<!-- 第一页 -->
<template>
  <div style="width:22.45rem;height:10.6625rem;overflow: hidden;" v-loading="loading" 
          element-loading-text="拼命加载中" 
          element-loading-spinner="el-icon-loading" 
          element-loading-background="rgba(255, 255, 255, 0.9)">
    <!-- 内容 -->
    <div class="content">
      <!-- 标题 -->
      <div class="couseTitle">
        <div class="couseText">{{title}}</div>
      </div>
      <!-- 内容 -->
      <div class="couseContentBox">
        <!-- top -->
        <div class="couse-top">
          <div class="couse-top-box" v-for="(item,index) in couseData" :key="item.id">
            <div class="couse-item">
              <img :src="item.thumb" alt="" style="width:100%;cursor:pointer;position: absolute;top:0;left:0;" @click="clickItem(index)">            
            </div>
            <div class="listTitle">{{index + 1}}.{{item.title}}</div>
          </div>
        </div>
        <!-- bottom -->
        <div class="couse-bottom">
          <el-pagination
            @current-change="handleCurrentChange"
            style="position: absolute;bottom:0;right:0;"
            small
            background
            :page-size="4"
            layout="prev, pager, next"
            :total="pageParam.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import apis from '../../../api/request'
export default {
// props: ["id"],
//import引入的组件需要注入到对象中才能使用
components: {
},
data() {
//这里存放数据
return {
  loading:false,
  title:"",
  pageParam: { page: 1, total: 0 },
  course_id:"",
  couseData:[],
};
},
//监听属性 类似于data概念
computed: {
  
},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
  clickItem(index){
    // console.log(index);
    localStorage.setItem("chapterId", this.couseData[index].id)
    this.$router.push(`/details/list/info/${this.couseData[index].id}`)
    // console.log(`/details/list/info/${this.couseData[index].course_id}`);
  },
  handleCurrentChange(val){
    this.loading = true
    this.pageParam.page = val
    // console.log(val);
    this.$get(apis.courseSection+this.course_id, {course_id:this.course_id,page: this.pageParam.page,limit:4}).then(res=>{
      if(res.code === 0){
        this.loading = false
        this.couseData = res.data.list
      }
      this.loading = false
      .catch(err=>{
        console.log(err);
        this.loading = false
      })
    })
  }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    // console.log(this.id);//可以了
    this.title = localStorage.getItem("courseName")
    this.course_id = localStorage.getItem("courseId")
    this.loading = true
    this.$get(apis.courseSection+this.course_id, {course_id:this.course_id,page: 1,limit:4}).then(res=>{
      // console.log(res);
      if(res.code === 0){
        this.couseData = res.data.list
        this.pageParam.total = res.data.total
        this.loading = false
      }
    })
},
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.couse-bottom{
    width: 100%;
    height: 0.425rem;
    position: absolute;
    bottom:0;
    /* background-color: blue; */
}
.couse-top-box{ 
    width:50%;
    height:50%;
    position: relative;
    float: left;
}
.couse-top{
    width: 100%;
    height:7.4125rem;
    position: absolute;
    top:0;
    /* background-color: red; */
}
.el-menu--horizontal > .is-active.el-menu-item{
  columns: #409eff;
}
.content{
    width: 14.04rem;
    height:9rem;
    float: left;
    position: absolute;
    z-index: 3;
    margin-top:0.8875rem;
    margin-left:5.25rem;
    background-color: #fff;
    box-shadow: 0px 6px 48px rgba(148, 148, 148, 0.13);
}
.couseTitle{
    width: 3.425rem;
    height: 0.6625rem;
    margin-left: -0.0875rem;
    margin-top: 0.225rem;
    position: relative;
    background-color: #5B87FF;
}
.couseText{
    width: 2.9rem;
    height: 0.4125rem;
    position: absolute;
    top:50%;
    left:50%;
    font-size: 0.3rem;
    margin-left: -1.45rem;
    margin-top: -0.20625rem;
    color: #fff;
}
.couseContentBox{
    width: 13.1rem;
    height: 7.9rem;
    /* background-color: yellow; */
    margin: 0.15rem auto;
    position: relative;
}
.couse-item{
    width: 6.25rem;
    height: 3rem;
    /* background-color: yellow; */
    overflow: hidden;
    position: absolute;
    left:50%;
    top:0;
    margin-left: -3.125rem;
    /* float: left; */
    /* margin-bottom:0.657rem; */
    /* margin-right: 0.3rem; */
}
.listTitle{
  width:6.25rem;
  text-align:left;
  font-size:0.19rem;
  font-weight: bold;
  position: absolute;
  bottom:10px;
  left:50%;
  margin-left: -3.125rem;
}
</style>